<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro13.png" alt="" />
    <div class="yj">
      <h4><span></span>医疗联合体</h4>
      <p>集团通过控股孵化器平台，投资医疗健康企业，组建医生集团、布局国际贸易、开展互联网大数据医疗产业、等多个业务线将行业资源通 过产业链条进行打通，合理配置资源，结合自身优势，完成由地方链接城镇，国内链接国际，科技结合产业的跨地区，跨国界的大健康领 域的横纵联合。目前旗下企业已与恒瑞集团、天江集团、京煤集团、北京同仁堂、北京中医药大学，天津大学、韩国医疗美容协会等国内 外知名机构院校开展多项项目合作，运用自主研发系统（PBM、健康教育平台等）与全国各园区作为通路将转化成果、优质资源迅速向基 层城市延伸部署，快速构建可孕育的医疗联合体雏形。</p>
      
    </div>
  </div>
  <div class="main" v-else>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.38rem;
        border-top: 0.3rem solid #e5e5e5;
        border-bottom: 0.4rem solid #e5e5e5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.1rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        p{
            width: 6.8rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
        }
        // :nth-child(2){
        //     margin-bottom: 0.2rem;
        // }
      }
      
    }
  }
  @media screen and (min-width: 960px) { 
    
  }
</style>